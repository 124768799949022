
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import store from '@/store';
import { createPreviewFromImage } from '@/utility';

@Options({
    components: {
    }
})
export default class surveyQuestionModal extends Vue {

    @Prop({
        default: () => new OM.SurveyQuestion()
    }) surveyQuestion: OM.SurveyQuestion;
    
    @Prop({
        type: [Function]
    }) callback: any;

    localModel: OM.SurveyQuestion = null;
    files: File[] = [];

    tempAnswer: OM.SurveyAnswer = new OM.SurveyAnswer();
    tempFile: File = null;
    previewtemp: string = "";

    created() {
        this.localModel = JSON.parse(JSON.stringify(this.surveyQuestion));

        this.localModel.surveyAnswers.forEach( x => {
            this.files.push(null);
        })
    }

    save() {
        this.callback(this.localModel, this.files);
    }

    get surveyImage(){
        if(this.localModel.image && this.localModel.image.publicUrl)
            return this.localModel.image.publicUrl;

        return "";
    }

    close() {
        this.$emit('close');
    }

    addValueTo(value: string, arr: string[]){
        if(!value)
            return;
        if(arr.some(x => x == value))
            return;

        arr.push(value);
    }

    removeValueFrom(value: string, arr: string[]){
        let index = arr.indexOf(value);
        if(index > -1){
            arr.splice(index, 1);
        }
    }

    addAnswer(){
        if(!this.tempAnswer.profileType || !this.tempAnswer.text.trim())
            return;
        
        this.tempAnswer.attachment.publicUrl = this.previewtemp;
        this.localModel.surveyAnswers.push(this.tempAnswer);
        this.files.push(this.tempFile);
        this.tempAnswer = new OM.SurveyAnswer();
        this.previewtemp = "";
    }

    async imageChangedTemp(file: File){
        if(!file)
            return;

		this.tempFile = file;

		this.previewtemp = await createPreviewFromImage(file);
        this.tempAnswer.attachment.publicUrl = this.previewtemp;
	}

    async imageChanged(file: File, answerIndex: number){
        if(!file)
            return;

		this.files.splice(answerIndex, 1, file);

		var preview = await createPreviewFromImage(file);
        this.localModel.surveyAnswers[answerIndex].attachment.publicUrl = preview;
	}
}
