import { CommonServices } from '@/services/CommonServices';
import { StorageServices } from '@/services/StorageServices';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { AppUserClient, InternalUserClient } from './Services';
import { baseUrl } from '@/config';

class _UploadFileServices {
    
    UploadAnswerAttachment(vm: VM.UploadAnswerFileVm, showSpinner = true) : Promise<void> {
        return CommonServices.uploadFileToUrl(baseUrl + 'api/SurveyConfig/UploadAnswerAttachment', null, vm, null, showSpinner)
    }

    UploadCustomFilterStartAnswerAttachment(vm: VM.UploadCustomFilterAnswerFileVm, showSpinner = true) : Promise<void> {
        return CommonServices.uploadFileToUrl(baseUrl + 'api/SurveyConfig/UploadCustomFilterStartAnswerAttachment', null, vm, null, showSpinner)
    }

    UploadCustomFilterEndAnswerAttachment(vm: VM.UploadCustomFilterAnswerFileVm, showSpinner = true) : Promise<void> {
        return CommonServices.uploadFileToUrl(baseUrl + 'api/SurveyConfig/UploadCustomFilterEndAnswerAttachment', null, vm, null, showSpinner)
    }

}

export let UploadFileServices = new _UploadFileServices();