
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { SurveyConfigClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';
import surveyQuestionModal from '../modal/surveyQuestionModal.vue';
import { UploadFileServices } from '@/services/UploadFileServices';
import surveyQuestionCustomFilterModal from '../modal/surveyQuestionCustomFilterModal.vue';

@Options({
    components: {
    }
})
export default class Survey extends Vue {

    surveyIdentifier: string = "";
    survey: OM.SurveyConfigVM = new OM.SurveyConfigVM();
    files: File[][] = [];

    created(){
        this.surveyIdentifier = this.$route.params.identifier as string;

        this.init();
    }

    init(){
        if(!this.surveyIdentifier)
            return;

        SurveyConfigClient.getById(this.surveyIdentifier)
        .then( x => {
            this.survey = x;

            this.files = [];
            x.questions.forEach( x => {
                this.files.push([]);
                x.surveyAnswers.forEach( x => {
                    this.files[this.files.length - 1].push(null);
                })
            })
        })
    }

    addOrEditQuestion(question: OM.SurveyQuestion = new OM.SurveyQuestion()){
        var index = this.survey.questions.indexOf(question);

        this.$opModal.show(surveyQuestionModal, {
            surveyQuestion: question,
            callback: (questionModal: OM.SurveyQuestion, files: File[]) => {
                if(question.order == null){
                    this.survey.questions.push(questionModal);
                    this.files.push([]);

                    files.forEach( x => {
                        this.files[this.files.length - 1].push(x);
                    })
                } else {
                    this.survey.questions.splice(index, 1, questionModal);

                    for(var i = 0; i < files.length; i++){
                        if(files[i] == null)
                            continue;
                        
                        this.files[index].splice(i, 1, files[i]);
                    }
                }

                this.$opModal.closeLast();

            }
        })
    }

    save(){
        SurveyConfigClient.save(this.survey)
        .then( x => {

            var proms = [];

            for(var i = 0; i < this.files.length; i++) {
                for(var y = 0; y < this.files[i].length; y++) {
                    if(!this.files[i][y])
                        continue;

                    // proms.push(SurveyConfigClient.updateQuestionImage(x, i, y, this.files[i][y]));   
                    proms.push(UploadFileServices.UploadAnswerAttachment({
                        questionIndex: i,
                        answerIndex: y,
                        identifier: x,
                        surveyConfigIdentifier: x,
                        file: this.files[i][y]
                    }));
                }
            }

            Promise.all(proms)
            .then( y => {
                this.survey.identifier = x;
                this.surveyIdentifier = x;
                this.$router.replace("/survey/" + x);
                this.init();
            })
        })
    }

    get orderedQuestion(){
        return this.survey.questions.sort( x => x.order);
    }

    get isDisabled(){
        return !this.survey.name.trim();
    }

    createQuestionCustomFilter(questionId: string) {
        SurveyConfigClient.setQuestionAsFilterableOnApp(this.survey.identifier, questionId)
        .then(x => {
            this.openEditCustomFilterModal(questionId);
        })
    }
    openEditCustomFilterModal(questionId: string) {
        var surveyQuestion = this.survey.questions.filter(x => x.identifier == questionId)[0];
        this.$opModal.show(surveyQuestionCustomFilterModal, {
            surveyIdentifier: this.survey.identifier,
            surveyQuestion: surveyQuestion
        }, 
        () => {
            this.init();
        })
    }
    removeQuestionCustomFilter(questionId: string) {
        SurveyConfigClient.removeQuestionFromAppFilters(this.survey.identifier, questionId)
        .then(x => {
            this.init();
        })
    }

    publishQuestion(){
        if(!this.surveyIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerPubblicareQuestoQuestionario", "Sei sicuro di voler pubblicare questo questionario?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.publish();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    publish(){
        SurveyConfigClient.publishById(this.surveyIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_PubblicatoConSuccesso", "Pubblicato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.init();
                    this.$opModal.closeLast();
                }
            })
        })
    }

    deleteSurvey(){
        if(!this.surveyIdentifier)
            return;

        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("backoffice_Attenzione", "Attenzione"),
            subtitle: this.$localizationService.getLocalizedValue("backoffice_SeiSicuroDiVolerEliminareQuestoQuestionario", "Sei sicuro di voler eliminare questo questionario?"),
            confirm: this.$localizationService.getLocalizedValue("backoffice_Si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                this.delete();
            },
            deny: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    delete(){
        SurveyConfigClient.deleteById(this.surveyIdentifier)
        .then( x => {
            this.$opModal.show(InfoModal, {
                text: this.$localizationService.getLocalizedValue("backoffice_Successo", "Successo"),
                subtitle: this.$localizationService.getLocalizedValue("backoffice_EliminatoConSuccesso", "Eliminato con successo!"),
                confirm: this.$localizationService.getLocalizedValue("backoffice_chiudi", "Chiudi"),
                confirmCb: () => {
                    this.$router.replace("/survey");
                    this.$router.push("/survey-list")
                    this.$opModal.closeLast();
                }
            })
        })
    }
}
