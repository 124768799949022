
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { SurveyConfigClient } from '@/services/Services';
import { createPreviewFromImage } from '@/utility';
import { UploadFileServices } from '@/services/UploadFileServices';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
    }
})
export default class surveyQuestionCustomFilterModal extends Vue {

    @Prop() surveyIdentifier: string;

    @Prop({
        default: () => new OM.SurveyQuestion()
    }) surveyQuestion: OM.SurveyQuestion;

    editModel: OM.EditAppCustomFilterInfoVM = new OM.EditAppCustomFilterInfoVM();
    uploadFileModel: VM.UploadCustomFilterAnswerFileVm = new VM.UploadCustomFilterAnswerFileVm();

    startPreviewtemp: string = "";
    endPreviewtemp: string = "";

    created() {
        this.editModel.surveyIdentifier = this.surveyIdentifier;
        this.editModel.questionIdentifier = this.surveyQuestion.identifier;

        if(this.surveyQuestion.appCustomFilter != null) {
            this.editModel.displayName = this.surveyQuestion.appCustomFilter.displayName;
            this.editModel.startAnswerIdentifier = this.surveyQuestion.appCustomFilter.startAnswer.answer.identifier;
            this.editModel.endAnswerIdentifier = this.surveyQuestion.appCustomFilter.endAnswer.answer.identifier;
    
            this.uploadFileModel.surveyConfigIdentifier = this.surveyIdentifier;
            this.uploadFileModel.questionIdentifier = this.surveyQuestion.identifier;
    
            if(this.surveyQuestion.appCustomFilter.startAnswer.appIcon != null && this.surveyQuestion.appCustomFilter.startAnswer.appIcon.publicUrl)
                this.startPreviewtemp = this.surveyQuestion.appCustomFilter.startAnswer.appIcon.publicUrl;
    
            if(this.surveyQuestion.appCustomFilter.endAnswer.appIcon != null && this.surveyQuestion.appCustomFilter.endAnswer.appIcon.publicUrl)
                this.endPreviewtemp = this.surveyQuestion.appCustomFilter.endAnswer.appIcon.publicUrl;
        }
    }

    get answersList() {
        var ris: OM.TextIdentifier[] = [];

        this.surveyQuestion.surveyAnswers.forEach(element => {
            var item = new OM.TextIdentifier();
            item.identifier = element.identifier;
            item.text = element.text;
            ris.push(item);
        });

        return ris;
    }

    save() {
        SurveyConfigClient.saveCustomFilterInfo(this.editModel)
        .then(x => {
            this.$emit('close');
        })
    }

    async imageChangedTempStart(file: File){
        if(!file)
            return;

		this.startPreviewtemp = await createPreviewFromImage(file);

        this.uploadFileModel.file = file;
        UploadFileServices.UploadCustomFilterStartAnswerAttachment(this.uploadFileModel)
        .then(x => {

        })
	}

    async imageChangedTempEnd(file: File){
        if(!file)
            return;

		this.endPreviewtemp = await createPreviewFromImage(file);
        
        this.uploadFileModel.file = file;
        UploadFileServices.UploadCustomFilterEndAnswerAttachment(this.uploadFileModel)
        .then(x => {

        })
	}
}
